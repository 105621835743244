import React from "react";
import { Link } from "gatsby";

export default function Navbar(props) {
	const [navbarOpen, setNavbarOpen] = React.useState(false);
	return (
		<>
			<nav
				className={
					(props.transparent
						? "top-0 absolute z-50 w-full"
						: "relative bg-gray-900 shadow-lg") +
					" flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg"
				}>
				<div className='container px-4 mx-auto flex flex-wrap items-center justify-between'>
					<div className='w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start'>
						<Link
							className={
								(props.transparent ? "text-white" : "text-white") +
								" text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase"
							}
							to='/'>
							The Pixel Prince
						</Link>
						<button
							className='cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none'
							type='button'
							onClick={() => setNavbarOpen(!navbarOpen)}>
							<i
								className={
									(props.transparent ? "text-white" : "text-gray-800") +
									" fas fa-bars"
								}></i>
						</button>
					</div>
					<div
						className={
							"lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +
							(navbarOpen ? " block rounded shadow-lg" : " hidden")
						}
						id='example-navbar-warning'>
						<ul className='flex flex-col lg:flex-row list-none lg:ml-auto'>
							<li className='flex items-center'>
								<a
									className={
										(props.transparent
											? "lg:text-white lg:hover:text-gray-300 text-gray-800"
											: "text-gray-800 hover:text-gray-600") +
										" px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
									}
									href='#pablo'>
									<i
										className={
											(props.transparent
												? "lg:text-gray-300 text-gray-500"
												: "text-gray-500") +
											" fab fa-facebook text-lg leading-lg "
										}
									/>
									<span className='lg:hidden inline-block ml-2'>Share</span>
								</a>
							</li>

							<li className='flex items-center'>
								<a
									className={
										(props.transparent
											? "lg:text-white lg:hover:text-gray-300 text-gray-800"
											: "text-gray-800 hover:text-gray-600") +
										" px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
									}
									href='#pablo'>
									<i
										className={
											(props.transparent
												? "lg:text-gray-300 text-gray-500"
												: "text-gray-500") +
											" fab fa-twitter text-lg leading-lg "
										}
									/>
									<span className='lg:hidden inline-block ml-2'>Tweet</span>
								</a>
							</li>

							<li className='flex items-center'>
								<a
									className={
										(props.transparent
											? "lg:text-white lg:hover:text-gray-300 text-gray-800"
											: "text-gray-800 hover:text-gray-600") +
										" px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
									}
									href='#pablo'>
									<i
										className={
											(props.transparent
												? "lg:text-gray-300 text-gray-500"
												: "text-gray-500") +
											" fab fa-github text-lg leading-lg "
										}
									/>
									<span className='lg:hidden inline-block ml-2'>Star</span>
								</a>
							</li>

							<li className='flex items-center'>
								<a
									className='ml-6 sm:ml-8 text-sm sm:text-base font-medium px-px border-b-2  border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-200 transition duration-150 ease-in-out'
									activeclassname='border-blue-600 text-gray-900 hover:border-blue-600'
									href='https://www.etsy.com/shop/thepixelprince'
									target='_blank'
									rel='noopener noreferrer'>
									<button
										className={
											(props.transparent
												? "bg-white text-gray-800 active:bg-gray-100"
												: "bg-white text-gray-800  active:bg-gray-100") +
											" text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 "
										}
										type='button'
										style={{ transition: "all .15s ease" }}>
										<i className='fas fa-arrow-alt-circle-down'></i>Etsy Shop
									</button>
								</a>
								<button
									className={
										(props.transparent
											? "bg-white text-gray-800 active:bg-gray-100"
											: "bg-white text-gray-800 active:bg-gray-100") +
										" text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
									}
									type='button'
									style={{ transition: "all .15s ease" }}>
									<i className='fas fa-arrow-alt-circle-down'></i> Our Story
								</button>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</>
	);
}
